// @flow strict
import React from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import styles from './Menu.module.scss';

type Props = {
  menu: {
    label: string,
    path: string,
    locale: string
  }[]
};

const Menu = ({ menu, intl }: Props) => (
  <nav className={styles['menu']}>
    <ul className={styles['menu__list']}>
      {menu.map((item) => (
        item.locale == intl.locale
        ? <li className={styles['menu__list-item']} key={item.path}>
            <Link
              to={item.path}
              className={styles['menu__list-item-link']}
              activeClassName={styles['menu__list-item-link--active']}
            >
              {item.label}
            </Link>
          </li>
        : ''
      ))}
    </ul>
  </nav>
);

export default Menu;
