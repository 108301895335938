import React from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-react-intl'
import styles from './Language.module.scss';

const languageName = {
  ja: '日本語',
  en: 'English',
}

const Language = () => {
  return (
    <div className={styles['language']}>
      <ul className={styles['language__list']}>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <a
                key={language}
                onClick={() => changeLocale(language)}
                href="/"
                className={
                  currentLocale == language
                  ? styles['language__list-lang-current']
                  : styles['language__list-lang-other']
                }
              >
                {languageName[language]}
              </a>
            ))
          }
        </IntlContextConsumer>
      </ul>
    </div>
  )
}
export default Language