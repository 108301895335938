// @flow strict
import React, { useRef, useEffect } from 'react';
import kebabCase from 'lodash/kebabCase';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';
import Language from './Language'
import { useSiteMetadata, useCategoriesList, useTagsList } from '../../hooks';
import { useIntl, Link } from 'gatsby-plugin-react-intl';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu } = useSiteMetadata();
  const categories = useCategoriesList();
  const tags = useTagsList();
  const intl = useIntl();
  const pageRef = useRef('Sidebar');

  if (isIndex == true){
    useEffect(() => {
      pageRef.current.scrollIntoView();
    });
  } else {
  }

  return (
    <div ref={pageRef} className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <Author author={author} isIndex={isIndex} intl={intl}/>
        <Menu menu={menu} intl={intl} />
        <Language />
        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <label>
              <input
                type="checkbox"
                onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                checked={theme === 'dark'}
              />{' '}
              Dark mode
            </label>
          )}
        </ThemeToggler>
        <Contacts contacts={author.contacts} />

        <div className={styles['sidebar__inner-tags']}>
          <h3>{intl.formatMessage({id: 'categories'})}</h3>
          <ul className={styles['sidebar__inner-tags-list']}>
            {categories.map((category) => (
              <li className={styles['sidebar__inner-tags-list-item']} key={category.fieldValue}>
                <Link to={`/category/${kebabCase(category.fieldValue)}/`} className={styles['sidebar__inner-tags-list-item-link']}>
                  {category.fieldValue}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles['sidebar__inner-tags']}>
          <h3>{intl.formatMessage({id: 'tags'})}</h3>
          <ul className={styles['sidebar__inner-tags-list']}>
            {tags.map((tag) => (
              <li className={styles['sidebar__inner-tags-list-item']} key={tag.fieldValue}>
                <Link to={`/tag/${kebabCase(tag.fieldValue)}/`} className={styles['sidebar__inner-tags-list-item-link']}>
                  {tag.fieldValue}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Sidebar;
