// @flow strict
import React from 'react';
// import { withPrefix, Link } from 'gatsby';
import { withPrefix } from 'gatsby';
import styles from './Author.module.scss';
import { Link } from "gatsby-plugin-react-intl"

type Props = {
  author: {
    name: string,
    bio: string,
    photo: string
  },
  isIndex: ?boolean
};

const Author = ({ author, isIndex, intl}: Props) => (
  <div className={styles['author']}>
    <h1 className={styles['author__title']}>
      <Link className={styles['author__title-link']} to='/'>Neuronalpail</Link>
    </h1>
    <Link to="/">
      <img
        src={withPrefix(author.photo)}
        className={styles['author__photo']}
        width="75"
        height="75"
        alt={author.name}
      />
    </Link>
{/* 
    { isIndex === true ? (
      <h1 className={styles['author__title']}>
        <Link className={styles['author__title-link']} to="/">{intl.formatMessage({ id: 'author.name' })}</Link>
      </h1>
    ) : (
      <h2 className={styles['author__title']}>
        <Link className={styles['author__title-link']} to="/">{intl.formatMessage({ id: 'author.name' })}</Link>
      </h2>
    )} */}
    <h2 className={styles['author__title']}>
      <Link className={styles['author__title-link']} to="/">{intl.formatMessage({ id: 'author.name' })}</Link>
    </h2>
    <p className={styles['author__subtitle']}>{intl.formatMessage({ id: 'author.bio' })}</p>
  </div>
);

export default Author;
